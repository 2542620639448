<!--
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-02-14 14:44:37
-->
<template>
  <div>
    <!-- 用户头 -->
    <personInfo></personInfo>
    <div class="allTable">
      <div class="allGoods">
        {{$t('w_0105')}} {{ carNum }}
        <div class="tips"
             v-if="isWarning">
          <div>{{$t('w_0106')}}</div>
          <img src="../../assets/images/x.png"
               alt=""
               @click="isWarning = false" />
        </div>
      </div>
      <div class="table_tit table_width">
        <div class="tab_i1"
             style="margin-left: 20px">{{$t('ENU_N_B_T_2')}}</div>
        <div>{{ $t('w_0107') }}</div>
        <div>{{ $t('w_0079') }}(¥)</div>
        <div>{{ $t('w_0080') }}(PV)</div>
        <div>{{ $t('MY_TH_2') }}</div>
        <div>{{ $t('w_0108') }}(￥)</div>
        <div>{{ $t('w_0109') }}(PV)</div>
        <div>{{ $t('MY_ORD_14') }}</div>
      </div>
      <div v-for="(item, index) in shopCarList"
           :key="index">
        <div class="t_top">
          <el-checkbox v-model="item.isSelected"
                       @change="checkClickArea($event, item)"></el-checkbox>
          <div class="zuanqu">{{ item.specialAreaVal }}</div>
        </div>
        <div v-for="(ctem, cndex) in item.shoppingCartList"
             :key="cndex"
             class="t_btm table_width">
          <div class="tab_i1">
            <el-checkbox v-model="ctem.isSelected"
                         @change="checkClick($event, item, cndex, ctem)"></el-checkbox>
            <img :src="ctem.cover1"
                 alt="" />
            <div class="tab_i1_1">{{ ctem.waresName }}</div>
          </div>
          <div class="tab_i2"
               v-if="item.specialArea!=21">
            <div v-for="(stem,sndex) in ctem.productParams"
                 :key="sndex"
                 class="tab_i2i">
              <div class="tab_i2il"> {{ stem.productName }}{{ stem.waresItemsParamsList.length>0&&stem.waresItemsParamsList[0].specsName }}</div>
              <div class="tab_i2ir">*{{ stem.waresItemsParamsList.length>0&&stem.waresItemsParamsList[0].quantity }}</div>
            </div>
          </div>
          <div class="tab_i2"
               v-else>
            <div v-for="(stem,sndex) in ctem.waresSkuList"
                 :key="sndex"
                 class="tab_i2i"
                 style="justify-content: center;">
              {{ stem.specValueNames}}
            </div>
          </div>
          <div class="hui">{{ userInfo.currencyIcon }}{{ ctem.waresPrice }}</div>
          <div class="hui">{{ ctem.achieve }}</div>
          <div>
            <el-input-number v-model="ctem.number"
                             @change="changeCar(item, ctem, index, cndex)"
                             :min="1"></el-input-number>
          </div>

          <div class="hui">{{ userInfo.currencyIcon }}{{ ctem.waresPrice*ctem.number |numberToCurrency }}</div>
          <div class="hui">{{ ctem.achieve*ctem.number |numberToCurrency }}</div>
          <div @click="delOne(ctem)"
               class="del1">{{ $t('ENU_CONTROL_TYPE_3') }}</div>
        </div>
      </div>
    </div>
    <div style="height: 100px"></div>
    <div class="footer">
      <div class="foot_l">
        <div @click="dellot"
             class="del">{{ $t('w_0110') }}</div>
        <div @click="emptyCar"
             class="del">{{ $t('w_0111') }}</div>
      </div>
      <div class="foot_r">
        <div>
          {{ $t('w_0112') }}<span class="red">{{ num }}</span>{{ $t('w_0113') }}
        </div>
        <div class="foot_ri red">
          {{ $t('w_0325') }}:
          <div>
            {{ userInfo.currencyIcon }}{{ totalPrice | numberToCurrency }}
          </div>
        </div>
        <div class="foot_ri lan">
          {{ $t('ENU_TOTAL_V_1') }}:
          <div>
            {{ totalAchive | numberToCurrency }}
          </div>
        </div>
        <div class="btn del"
             v-show="this.goodsCheck.length == 1"
             @click="toBuy">
          {{ $t('w_0085') }}
        </div>
        <div class="btn1"
             v-show="this.goodsCheck.length != 1">{{ $t('w_0085') }}</div>
      </div>
    </div>
    <el-dialog title="赠品列表"
               :visible.sync="dialogVisible2"
               width="50%"
               :before-close="handleClose2">
      <el-table :data="tableData"
                style="width: 100%"
                height="400px"
                :header-cell-style="{ background: '#EEEEEE' }"
                :row-class-name="tableRowClassName"
                @selection-change="handleSelectionChange">
        <el-table-column type="selection"
                         width="55"> </el-table-column>
        <el-table-column align="center"
                         prop="cover1"
                         label="商品图片">
          <template slot-scope="scope">
            <img class="bgImg"
                 :src="scope.row.cover1"
                 alt="" />
          </template>
        </el-table-column>
        <el-table-column align="center"
                         prop="waresName"
                         label="商品名称">
        </el-table-column>
        <el-table-column align="center"
                         prop="waresCode"
                         label="商品编号">
        </el-table-column>
        <el-table-column align="center"
                         prop="waresPrice"
                         label="商品价格">
        </el-table-column>
        <el-table-column align="center"
                         prop=""
                         label="数量">
          <template slot-scope="scope">
            <el-input-number size="mini"
            @change="calPrice"
                             v-model="scope.row.quantity"
                             :min="1"></el-input-number>
          </template>
        </el-table-column>
      </el-table>
      <div class="total">合计:{{ total |toThousandthAndKeepDecimal}}</div>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary"
                   @click="tableTrue">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import personInfo from '@/components/personInfo.vue'
import * as api from '@/api/goods.js'
import { memLevel } from '@/api/register.js'
import { mapGetters } from 'vuex'
export default {
  components: {
    personInfo,
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  data() {
    return {
      shopCarList: [],
      goodsCheck: [],
      totalPrice: 0,
      totalAchive: 0,
      num: 0,
      isWarning: true,
      carNum: 0,
      tableData: [],
      dialogVisible2:false,
      selTable:[],
      total:'0.00'
    }
  },
  mounted() {
    this.getCarList()
    this.getShoppingCount()
  },
  methods: {
    getShoppingCount() {
      api.getShoppingCount().then((res) => {
        this.carNum = res.data.cont
      })
    },
    toBuy() {
      let shoppArr = this.goodsCheck[0].orderItemsParams.map((item) => {
        return item.shoppingId
      })
      localStorage.setItem('allGoods', '')
      localStorage.setItem('allGoods', JSON.stringify(shoppArr))
      if (
        this.goodsCheck[0].specialArea == 3 ||
        this.goodsCheck[0].specialArea == 12 ||
        this.goodsCheck[0].specialArea == 14 ||
        this.goodsCheck[0].specialArea == 22 ||
        this.goodsCheck[0].specialArea == 26 ||
        this.goodsCheck[0].specialArea == 28
      ) {
        this.$router.push({
          path: 'repurchase',
          query: {
            specialArea: this.goodsCheck[0].specialArea,
          },
        })
      }else if(this.goodsCheck[0].specialArea == 31){
        if (this.totalPrice < 1000) {
          this.$message({
            message: "购买商品总价格不能低于1000元",
            type: "warning",
          });
          return false;
        }else{
          this.$router.push({
          path: "repurchase",
          query: {
            specialArea: this.goodsCheck[0].specialArea,
          },
        });
        }
      }else if (this.goodsCheck[0].specialArea == 30) {
        if (this.goodsCheck[0].orderItemsParams.length > 1) {
          this.$message({
            message: "续约专区每次只能购买一件商品",
            type: "warning",
          });
          return false;
        } else {
          let num = this.goodsCheck[0].orderItemsParams.some(
            (item) => item.quantity > 1
          );
          if (num) {
            this.$message({
              message: "续约专区每次只能购买一件商品",
              type: "warning",
            });
            return false;
          } else {
            this.$router.push({
              path: "repurchase",
              query: {
                specialArea: this.goodsCheck[0].specialArea,
              },
            });
          }
        }
      } else if (this.goodsCheck[0].specialArea == 13) {
        this.$router.push({
          path: 'welfare',
        })
      } else if (this.goodsCheck[0].specialArea == 14) {
        this.$router.push({
          path: 'tvIng',
        })
      } else if (this.goodsCheck[0].specialArea == 11) {
        this.$router.push({
          path: 'integral',
        })
      } else if (this.goodsCheck[0].specialArea == 10) {
        this.$router.push({
          path: 'rescission',
        })
      } else if (
        this.goodsCheck[0].specialArea == 1 ||
        this.goodsCheck[0].specialArea == 24
      ) {
        // let deleteList = []
        // shoppArr.forEach(item=>{
        //   deleteList.push({
        //     shoppingId:item
        //   })
        // })
        //   api.validShop({
        //     specialArea: this.goodsCheck[0].specialArea,
        //     deleteList:deleteList
        //   }).then(res => {
        //       if (res.code == 200) {
        //           this.$router.push({
        //               path: 'register',
        //               query: {
        //                   specialArea: this.goodsCheck[0].specialArea,
        //               },
        //           })
        //       } else {
        //           this.$message({
        //               type: 'warning',
        //               message: res.msg,
        //           })
        //       }
        //   })
        let deleteList = []
        shoppArr.forEach((item) => {
          deleteList.push({
            shoppingId: item,
          })
        })
        api
          .validShop({
            specialArea: this.goodsCheck[0].specialArea,
            deleteList: deleteList,
          })
          .then((res) => {
            api
              .energyShop({
                specialArea: this.goodsCheck[0].specialArea,
                deleteList: deleteList,
              })
              .then((response) => {
                if (response.data && response.data.length > 0) {
                  this.tableData = response.data
                  this.tableData.forEach((item) => {
                    this.$set(item, 'quantity', 1)
                  })
                  this.dialogVisible2 = true
                } else {
                  this.$router.push({
                    path: 'register',
                    query: {
                      specialArea: this.goodsCheck[0].specialArea,
                    },
                  })
                }
              })
          })
      } else if (this.goodsCheck[0].specialArea == 2) {
        // let deleteList = []
        // shoppArr.forEach((item) => {
        //   deleteList.push({
        //     shoppingId: item,
        //   })
        // })
        // api
        //   .validShop({
        //     specialArea: this.goodsCheck[0].specialArea,
        //     deleteList: deleteList,
        //   })
        //   .then((res) => {
        //     if (res.code == 200) {
        //       this.$router.push({
        //         path: 'upgrade',
        //         query: {
        //           specialArea: this.goodsCheck[0].specialArea,
        //         },
        //       })
        //     } else {
        //       this.$message({
        //         type: 'warning',
        //         message: res.msg,
        //       })
        //     }
        //   })
        let deleteList = []
        shoppArr.forEach((item) => {
          deleteList.push({
            shoppingId: item,
          })
        })
        api
          .validShop({
            specialArea: this.goodsCheck[0].specialArea,
            deleteList: deleteList,
          })
          .then((res) => {
            api
              .energyShop({
                specialArea: this.goodsCheck[0].specialArea,
                deleteList: deleteList,
              })
              .then((response) => {
                if (response.data && response.data.length > 0) {
                  this.tableData = response.data
                  this.tableData.forEach((item) => {
                    this.$set(item, 'quantity', 1)
                  })
                  this.dialogVisible2 = true
                } else {
                  this.$router.push({
                    path: 'upgrade',
                    query: {
                      specialArea: this.goodsCheck[0].specialArea,
                    },
                  })
                }
              })
          })
      } else if (
        this.goodsCheck[0].specialArea == 25 ||
        this.goodsCheck[0].specialArea == 27
      ) {
        this.$router.push({
          path: 'upgrade',
          query: {
            specialArea: this.goodsCheck[0].specialArea,
          },
        })
      } else if (
        this.goodsCheck[0].specialArea == 7 ||
        this.goodsCheck[0].specialArea == 101
      ) {
        this.$router.push({
          path: 'hiFans',
          query: {
            specialArea: this.goodsCheck[0].specialArea,
          },
        })
      } else if (this.goodsCheck[0].specialArea == 18) {
        this.$router.push({
          path: 'spaceRegister',
          query: {
            specialArea: 18,
          },
        })
      } else if (this.goodsCheck[0].specialArea == 19) {
        this.$router.push({
          path: 'spaceRegister',
          query: {
            specialArea: 19,
          },
        })
      } else if (this.goodsCheck[0].specialArea == 21) {
        this.$router.push({
          path: 'shareConfirm',
          query: {
            specialArea: 21,
            allData: JSON.stringify(this.goodsCheck[0].orderItemsParams),
          },
        })
      }
    },
    // 多删
    dellot() {
      let deleteList = []
      this.shopCarList.forEach((item) => {
        item.shoppingCartList.forEach((ctem) => {
          if (ctem.isSelected) {
            deleteList.push({
              shoppingId: ctem.shoppingId,
            })
          }
        })
      })
      this.$confirm(this.$t('w_0290') + '?', this.$t('w_0034'), {
        confirmButtonText: this.$t('w_0035'),
        cancelButtonText: this.$t('ENU_P_TYPE0'),
        type: 'warning',
      }).then(() => {
        api.batchDelShopping({ deleteList: deleteList }).then((res) => {
          this.getCarList()
          this.getShoppingCount()
          this.$message({
            type: 'success',
            message: this.$t('w_0089') + '!',
          })
        })
      })
    },
    handleSelectionChange(val) {
      // waresPrice
      let total = 0
      val.forEach(item => {
        total+=Number(item.waresPrice)*item.quantity
      })
      this.total = total
      this.selTable = val
    },
    calPrice(e){
      let total = 0
      this.selTable.forEach(item => {
        total+=Number(item.waresPrice)*item.quantity
      })
      this.total = total
    },
    handleClose2() {
      this.dialogVisible2 = false
    },
    tableTrue() {
      if (this.selTable.length > 0) {
        let a = {
          specialArea: this.goodsCheck[0].specialArea,
          orderItemsParams: [],
        }
        a.orderItemsParams = this.selTable.map((item) => {
          return {
            waresCode: item.waresCode,
            quantity: item.quantity,
            waresItemsParamList: item.productGroup,
            energySilo: 1,
          }
        })
        a.orderItemsParams.forEach((item) => {
          item.waresItemsParamList = item.waresItemsParamList.map((ctem) => {
            return {
              pkWaresSpecsSku: ctem.pkSkuId,
              quantity:ctem.quantity
            }
          })
        })
        api.energyShopSilo(a).then((res) => {
          if (res.code == 200) {
            this.dialogVisible2 = false
            if (this.goodsCheck[0].specialArea == 1) {
              this.$router.push({
                path: 'register',
                query: {
                  specialArea: this.goodsCheck[0].specialArea,
                  selTable: JSON.stringify(this.selTable),
                },
              })
            } else if (this.goodsCheck[0].specialArea == 2) {
              this.$router.push({
                path: 'upgrade',
                query: {
                  specialArea: this.goodsCheck[0].specialArea,
                  selTable: JSON.stringify(this.selTable),
                },
              })
            }
          }
        })
      } else {
        this.$message({
          message: '请选择赠品',
          type: 'warning',
        })
      }
    },
    // 清空购物车
    emptyCar() {
      let deleteList = []
      this.shopCarList.forEach((item) => {
        item.shoppingCartList.forEach((ctem) => {
          deleteList.push({
            shoppingId: ctem.shoppingId,
          })
        })
      })
      this.$confirm(this.$t('w_0288') + '?', this.$t('w_0034'), {
        confirmButtonText: this.$t('w_0035'),
        cancelButtonText: this.$t('ENU_P_TYPE0'),
        type: 'warning',
      }).then(() => {
        api.batchDelShopping({ deleteList: deleteList }).then((res) => {
          this.getCarList()
          this.$message({
            type: 'success',
            message: this.$t('w_0089') + '!',
          })
        })
      })
      this.getShoppingCount()
    },
    // 单删
    delOne(ctem) {
      let deleteList = [
        {
          shoppingId: ctem.shoppingId,
        },
      ]
      this.$confirm(this.$t('w_0289') + '?', this.$t('w_0034'), {
        confirmButtonText: this.$t('w_0035'),
        cancelButtonText: this.$t('ENU_P_TYPE0'),
        type: 'warning',
      }).then(() => {
        api.batchDelShopping({ deleteList: deleteList }).then((res) => {
          this.getCarList()
          this.getShoppingCount()
          this.$message({
            type: 'success',
            message: this.$t('w_0089') + '!',
          })
        })
      })
    },
    // 修改数量
    changeCar(item, ctem, index, cndex) {
      api
        .plusReduceShopping({
          shoppingId: ctem.shoppingId,
          number: ctem.number,
          specialArea: item.specialArea,
        })
        .then((res) => {
          this.checkArea()
          this.getShoppingCount()
        })
        .catch((err) => {
          this.getCarList()
        })
    },
    getCarList() {
      api.getShopping().then((res) => {
        res.data.forEach((item) => {
          item.isSelected = false
          item.shoppingCartList.forEach((ctem) => {
            ctem.isSelected = false
            ctem.waresItemsParamList = []
            if (item.specialArea != 21) {
              ctem.productParams.forEach((stem) => {
                if (stem.waresItemsParamsList.length > 0) {
                  stem.waresItemsParamsList[0].pkWaresSpecsSku =
                    stem.waresItemsParamsList[0].pkSkuId
                  stem.waresItemsParamsList[0].productName = stem.productName
                  ctem.waresItemsParamList.push(stem.waresItemsParamsList[0])
                }
              })
            } else {
            }
          })
        })
        this.shopCarList = res.data
      })
    },
    checkClickArea(e, item) {
      item.shoppingCartList.forEach((ctem) => {
        if (e) {
          ctem.isSelected = true
        } else {
          ctem.isSelected = false
        }
      })
      this.checkArea()
    },
    checkClick(e, item) {
      let arr = []
      item.shoppingCartList.forEach((ctem) => {
        if (ctem.isSelected == true) {
          arr.push(1)
        }
      })
      if (arr.length == item.shoppingCartList.length) {
        item.isSelected = true
      } else {
        item.isSelected = false
      }
      this.checkArea()
    },
    // 校验选中了几个专区的商品
    checkArea() {
      let arr = []
      this.shopCarList.forEach((item) => {
        item.shoppingCartList.forEach((ctem) => {
          if (ctem.isSelected) {
            arr.push({
              specialArea: item.specialArea,
              orderItemsParams: [
                {
                  waresCode: ctem.waresCode,
                  shoppingId: ctem.shoppingId,
                  waresName: ctem.waresName,
                  achieve: ctem.achieve,
                  waresPrice: ctem.waresPrice,
                  cover1: ctem.cover1,
                  quantity: ctem.number,
                  waresItemsParamList: ctem.waresItemsParamList,
                  waresSkuList: ctem.waresSkuList || [],
                  source: ctem.source || '',
                },
              ],
            })
          }
        })
      })
      const arrayHashmap = arr.reduce((obj, item) => {
        obj[item.specialArea]
          ? obj[item.specialArea].orderItemsParams.push(
              ...item.orderItemsParams
            )
          : (obj[item.specialArea] = item)
        return obj
      }, {})
      this.goodsCheck = Object.values(arrayHashmap)
      this.checkPrice()
    },
    // 计算价格
    checkPrice() {
      let totalPrice = 0
      let totalAchive = 0
      let num = 0
      this.goodsCheck.forEach((item) => {
        item.orderItemsParams.forEach((ctem) => {
          totalPrice += parseFloat(ctem.waresPrice * ctem.quantity)
          totalAchive += parseFloat(ctem.achieve * ctem.quantity)
          num += ctem.quantity
        })
      })
      // if (this.goodsCheck.length != 1) {
      this.totalPrice = totalPrice.toFixed(2)
      this.totalAchive = totalAchive.toFixed(2)
      this.num = num
      // }
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return 'warning-row'
      } else if (rowIndex % 2 == 0) {
        return 'success-row'
      }
      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
.allGoods {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  padding: 40px 0 10px 60px;
  position: relative;
}

.table_tit {
  background: #eeeeee;
  padding: 30px 60px;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
}

.table_width {
  text-align: center;
  div:nth-child(2) {
    width: 20%;
  }
  div:nth-child(3) {
    width: 11%;
  }
  div:nth-child(4) {
    width: 11%;
  }
  div:nth-child(5) {
    width: 11%;
  }
  div:nth-child(6) {
    width: 11%;
  }
  div:nth-child(7) {
    width: 11%;
  }
}

.t_top {
  display: flex;
  align-items: center;
  border-bottom: 5px solid rgba(7, 7, 7, 0.05);
  padding: 62px 0 10px 60px;
  div {
    margin-left: 10px;
  }
}

.t_btm {
  display: flex;
  align-items: center;
  padding: 20px 60px;
  border-bottom: 5px solid rgba(7, 7, 7, 0.05);
  img {
    width: 88px;
    height: 88px;
    border-radius: 8px 8px 8px 8px;
    margin-left: 10px;
  }
}

.tab_i1 {
  display: flex;
  align-items: center;
  flex: 1;
}

.tab_i1_1 {
  flex: 1;
  text-align: left;
  margin-left: 10px;
  font-size: 14px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}

.tab_i2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  .tab_i2i {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;
    .tab_i2il {
      width: 80% !important;
      text-align: left;
    }
    .tab_i2ir {
      color: #d61820;
    }
  }
}

.tips {
  position: absolute;
  background: #efefef;
  border-radius: 8px 8px 8px 8px;
  font-size: 18px;
  font-weight: 400;
  color: #666666;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  right: 65px;
  top: 20px;
  img {
    width: 12px;
    height: 12px;
    margin-left: 20px;
  }
}

.footer {
  background: #ededed;
  bottom: 0;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 10;
  .foot_l {
    margin-left: 30px;
    display: flex;
    align-items: center;
    div:nth-child(1) {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
    }
    div:nth-child(2) {
      font-size: 14px;
      font-weight: 400;
      color: #333;
      margin-left: 20px;
    }
  }
  .foot_r {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333;
  }
  .foot_ri {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
  .red {
    font-size: 18px;
    color: #f43c3a;
  }
  .lan {
    font-size: 18px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #384a8a;
  }
  .btn {
    font-size: 18px;
    color: #ffffff;
    background: #d61820;
    border-radius: 8px 8px 8px 8px;
    padding: 25px 40px;
    margin-left: 20px;
  }
  .btn1 {
    font-size: 18px;
    color: #ffffff;
    background: #ddd;
    border-radius: 8px 8px 8px 8px;
    padding: 25px 40px;
    margin-left: 20px;
  }
}

.del {
  cursor: pointer;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

.del1 {
  cursor: pointer;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #d61820;
}

.hui {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

.zuanqu {
  font-size: 22px;
  font-weight: 600;
  color: #333333;
}
.total{
  text-align: right;
  font-size: 14px;
  padding: 5px 10px;
}
.bgImg {
  width: 48px;
  height: 48px;
}
</style>